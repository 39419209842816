// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { AlertType } from '@/_typings/enums/alertType'
import { IContact } from '@/_typings/interfaces/contact'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import Badge from '@/components/controls/badge/index.vue'
import Dropdown from '@/components/controls/drop-down/index.vue'
import TextInput from '@/components/controls/text-input/index.vue'
import Toggle from '@/components/controls/toggle/index.vue'
import getPageTitle from '@/utils/getPageTitle'
import { getIsActiveBadgeInfo } from '@/utils/getBadgeInfo'

export default defineComponent({
  name: 'contact',
  components: {
    Badge,
    Dropdown,
    TextInput,
    Toggle
  },
  getPageTitle,
  getIsActiveBadgeInfo,
  data() {
    return {
      accountList: {} as IPagedList,
      contact: {} as IContact,
      isSaving: false,
      readonly: false,
      validatorKey: 0
    }
  },
  created() {
    if (!!this.$route.params.contactId) { // view / edit
      this.fetchContact({ contactId: this.$route.params.contactId }).then(data => {
        this.contact = data
      })
      this.readonly = true
    } else { // new
      this.contact = { isActive : true } as IContact
    }
    this.fetchAccounts({ pageInfo: { pageSize: 250, sortedBy: 'name', sortDirection: 'Ascending' }, contactId: this.$route.params.contactId, activeOnly: true }).then(data => { this.accountList = data })
  },
  methods: {
    ...mapMutations([
      'setAlert',
      'setBreadcrumbText'
    ]),
    ...mapActions([
      'addContact',
      'fetchAccounts',
      'fetchContact',
      'updateContact'
    ]),
    saveContact() {
      this.validatorKey++
      this.$nextTick(() => {
        if (this.$el.querySelectorAll('#contact .form-input .invalid').length) return
        this.isSaving = true
        const action = this.contact.id ? this.updateContact : this.addContact
        action({ contact: this.contact })
          .then((data) => {
            if (this.contact.id) {
              this.contact = data
              this.setBreadcrumbText(this.contact.fullName)
              this.readonly = true
            } else {
              this.$router.push({ path: '/refresh', query: { name: 'contact', id: data.id } })
            }
            this.setAlert({ type: AlertType.Success, title: this.$t('reseller_contact-save-1_message'), message: this.$t('reseller_contact-save-2_message'), timeout: 3000 })
          })
          .finally(() => {
            this.isSaving = false
          })
      })
    }
  }
})

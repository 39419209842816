<!-- (c) Cincom Systems, Inc. <2018> - <2022> -->
<!-- ALL RIGHTS RESERVED                      -->
<template>
  <div class="toggle" :class="{ 'invalid': invalid, 'disabled': disabled, 'is-change-processing': isChangeProcessing, 'read-only': readonly }" @keypress.space="toggle">
    <label-c v-if="label" :disabled="disabled">{{ label }}</label-c>
    <input type="checkbox" tabindex="-1" class="toggle-input" :value="value" />
    <div class="toggle-trigger" @click="toggle">
      <label class="toggle-field form-control" :class="{ 'is-selected': value }" :tabindex="disabled ? -1 : 0">
        <span class="label-off">{{ offLabel }}</span>
        <span class="label-on">{{ onLabel }}</span>
      </label>
    </div>
  </div>
</template>
<script src="./toggle.ts"></script>
<style src="./toggle.scss" lang="scss"></style>

// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED                     
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import LabelC from '@/components/controls/label-c/index.vue'

export default defineComponent({
  name: 'toggle',
  components: {
    LabelC
  },
  props: {
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    invalid: { type: Boolean, default: false },
    onLabel: { type: String, default: null },
    offLabel: { type: String, default: null },
    readonly: { type: Boolean, default: false },
    value: { type: Boolean, default: false }
  },
  emits: [ 'input' ],
  computed: {
    ...mapGetters({
      isChangeProcessing: 'getIsChangeProcessing'
    }),
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
})

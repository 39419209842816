// (c) Cincom Systems, Inc. <2018> - <2022>
// ALL RIGHTS RESERVED
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { IColumn } from '@/_typings/interfaces/column'
import { IPagedList } from '@/_typings/interfaces/paged-list'
import GridTable from '@/components/controls/grid-table/index.vue'
import confirmationTypes from '@/components/modals/confirmation/confirmationTypes'
import showConfirmationModal from '@/utils/showConfirmationModal'

export default defineComponent({
  name: 'contacts',
  components: {
    GridTable
  },
  data() {
    return {
      pagedList: {} as IPagedList,
      selectedItems: [] as any[]
    }
  },
  created() {
    this.getPagedItems({ sortedBy: 'fullName', sortDirection: 'Ascending' })
  },
  computed: {
    columns() : IColumn[] {
      return [
        { id: 'fullName', dataType: 'String', label: this.$t('contact_full-name_label'), name: 'fullName', isHyperlink: true },
        { id: 'accountName', dataType: 'String', label: this.$t('contact_account_label'), name: 'accountName' },
        { id: 'title', dataType: 'String', label: this.$t('contact_title_label'), name: 'title' },
        { id: 'email', dataType: 'String', label: this.$t('contact_email_label'), name: 'email'}
      ] as IColumn[]
    }
  },
  methods: {
    ...mapActions([
      'deleteContact',
      'deleteContacts',
      'fetchContacts'
    ]),
    deleteItem({ id }) {
      showConfirmationModal(confirmationTypes.DELETE, () => {
        this.deleteContact({ contactId: id }).then(() => {
          this.getPagedItems({})
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.id !== id)
        })
      })
    },
    deleteItems() {
      showConfirmationModal(confirmationTypes.DELETE_MULTIPLE, () => {
        this.deleteContacts({ contactIds: this.selectedItems.map(selectedItem => selectedItem.id) }).then(() => {
          this.getPagedItems({})
          this.selectedItems = []
        })
      })
    },
    getPagedItems(pageInfo: any) {
      this.fetchContacts({ pageInfo: { ...this.pagedList.paging, ...pageInfo } }).then(data => {
        this.pagedList = data
      })
    },
    navigateToContact(id) {
      this.$router.push({ name: id ? 'contact' : 'contact-new', params: { contactId: id ? id : null } })
    }
  }
})
